import React, { useEffect, useState } from 'react';
import './FormField.scss';

// import passOff from 'img/modal/pass_off.png';
// import passOn from 'img/modal/pass_on.png';

export default function FormTextField(props: any) {
  const [showPassword, setShowPassword] = useState(false);
  const [displayValue, setDisplayValue] = useState('');
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');
  const {
    className,
    type,
    name,
    validation,
    register,
    error,
    errorCheck,
    errorText,
    arrowPosition,
    defaultValue,
    onBlur,
    onFocus,
    value,
    autoFocus,
    onChange,
    disabled,
    placeholder,
    japanMode = false,
    ...rest
  } = props;

  const [inputTypeJp, setInputTypeJp] = useState(type || 'text');

  useEffect(() => {
    if (errorCheck) {
      setIsError(true);
      setMessage(errorText);
    } else if (error) {
      setIsError(error);
      setMessage(error.message);
    } else {
      setIsError(false);
      setMessage('');
    }
  });

  const handleClickShowPasssword = () => {
    setShowPassword(!showPassword);
  };

  // 일본용에서는 자물쇠 on/off 모두 마스킹 처리.
  // 단, 자물쇠 누르고 있을때는 맨 마지막 글자만 보임.
  const onMouseDown = () => {
    const maskedValue =
      value?.length > 0
        ? '●'.repeat(value.length - 1) + (value?.length > 0 ? value[value.length - 1] : '')
        : value;
    setDisplayValue(maskedValue);
    setInputTypeJp('text');
  };

  const onMouseUp = () => {
    setDisplayValue('');
    setInputTypeJp('password');
  };

  const isLock = japanMode ? !displayValue : !showPassword;
  const inputTypeKo = showPassword ? 'text' : type;
  const inputType = japanMode ? inputTypeJp : inputTypeKo;

  return (
    <div className="form-field-wrap">
      <div className="form-wrap">
        {value ? (
          <input
            name={name}
            className={`${className} ${isError && ' validate-error'}`}
            type={inputType}
            ref={validation && register ? register(validation) : null}
            value={displayValue || value}
            onChange={onChange || undefined}
            onBlur={onBlur || undefined}
            onFocus={onFocus || undefined}
            disabled={disabled}
            placeholder={placeholder || undefined}
          />
        ) : (
          <input
            name={name}
            className={`${className} ${isError && ' validate-error'}`}
            type={inputType}
            ref={validation && register ? register(validation) : null}
            defaultValue={defaultValue || ''}
            onChange={onChange || undefined}
            onBlur={onBlur || undefined}
            onFocus={onFocus || undefined}
            disabled={disabled}
            autoFocus={autoFocus || false} // eslint-disable-line
            placeholder={placeholder || undefined}
          />
        )}
        {/* 우측 영역 */}
        {type === 'password' ? (
          <div
            className="right-box"
            {...(japanMode // eslint-disable-line
              ? {
                  onMouseDown: onMouseDown,
                  onMouseUp: onMouseUp,
                  onMouseMove: onMouseUp,
                }
              : {
                  onClick: handleClickShowPasssword,
                  onKeyDown: handleClickShowPasssword,
                })}
            aria-hidden="true"
          >
            {isLock ? (
              <img src="/img/modal/pass_off.png" alt="" draggable="false" />
            ) : (
              <img src="/img/modal/pass_on.png" alt="" draggable="false" />
            )}
          </div>
        ) : null}
      </div>
      {isError ? <div className={`arrow-box ${arrowPosition}`}>{message}</div> : null}
    </div>
  );
}
