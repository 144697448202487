import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { Option } from 'utils/commonValues';
import { addLicense, updateLicense } from 'store/license';
import { timeFormatFromUTCEpoch } from 'utils/commonFunctions';
import * as valid from 'utils/validation';
import Loading from 'components/common/Loading';
import ModalTemplate from 'components/common/ModalTemplate';
import FormTextField from 'components/common/FormTextField';
import FormSelectField from 'components/common/FormSelectField';
import FormDatePicker from 'components/common/FormDatePicker';
import './LicenseAddEdit.scss';

function LicenseAddEdit({ visible, isAdmin, isAddMode, dataInfo, toggleModal }: any) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { errors, control, register, getValues, setValue, setError, handleSubmit } = useForm({
    mode: 'all',
  });
  const userData = useSelector((state: any) => state.user.user);
  const [formValues, setFormValues]: any = useState({
    serviceNo: dataInfo.serviceNo,
    licenseType: dataInfo.licenseType,
    examLimitCount: dataInfo.examLimitCount,
    startEpoch: isAddMode ? moment().startOf('day') : moment(dataInfo.startEpoch * 1000),
    endEpoch: isAddMode
      ? moment().startOf('day').add(1, 'y').subtract(1, 's').endOf('day')
      : moment(dataInfo.endEpoch * 1000),
    licenseCount: dataInfo.licenseCount,
    approve: isAddMode ? 1 : dataInfo.approve,
    licenseEnabled: isAddMode ? 1 : dataInfo.licenseEnabled,
  });
  
  const LicenseString: any = { 
    Duration: formatMessage({ id: 'License_7', defaultMessage: '기간 라이선스' }),
    Times: formatMessage({ id: 'License_8', defaultMessage: '횟수 라이선스' }),
    DurationTimes: formatMessage({ id: 'License_58', defaultMessage: '기간+횟수 라이선스' }),
    EduDuration: 
      formatMessage({ id: 'License_60', defaultMessage: '[교육]' }) 
      + formatMessage({ id: 'License_7', defaultMessage: '기간 라이선스' }),
    EduDurationTimes: 
      formatMessage({ id: 'License_60', defaultMessage: '[교육]' }) 
      + formatMessage({ id: 'License_58', defaultMessage: '횟수 라이선스' })
  };

  if(Option.isJapan === 1){
    LicenseString.Duration = 
      formatMessage({ id: 'License_61', defaultMessage: '[훈련]' }) 
      + formatMessage({ id: 'License_7', defaultMessage: '기간 라이선스' });
    LicenseString.Times = 
      formatMessage({ id: 'License_61', defaultMessage: '[훈련]' }) 
      + formatMessage({ id: 'License_8', defaultMessage: '횟수 라이선스(무제한)' });
    LicenseString.DurationTimes = 
      formatMessage({ id: 'License_61', defaultMessage: '[훈련]' }) 
      + formatMessage({ id: 'License_58', defaultMessage: '횟수 라이선스' });
    LicenseString.EduDuration = 
      formatMessage({ id: 'License_60', defaultMessage: '[교육]' }) 
      + formatMessage({ id: 'License_7', defaultMessage: '기간 라이선스' });
    LicenseString.EduDurationTimes = 
      formatMessage({ id: 'License_60', defaultMessage: '[교육]' }) 
      + formatMessage({ id: 'License_58', defaultMessage: '횟수 라이선스' });
  }

  const menuListLicense: Array<any> = [];
  menuListLicense.push({
      key: 2,
      value: 2,
      text: LicenseString.Duration,
    },
    {
      key: 4,
      value: 4,
      text: LicenseString.Times,
    },
    {
      key: 8,
      value: 8,
      text: LicenseString.DurationTimes,
    },);
  if (Option.isJapan === 1){
    menuListLicense.push({
        key: 16,
        value: 16,
        text: LicenseString.EduDuration,
      },{
        key: 32,
        value: 32,
        text: LicenseString.EduDurationTimes,
      },);
  }
  // 시작일자, 종료일자 선택 시
  const handleOnChangeDate = (type: string, current: any) => {
    if (type === 'startEpoch') {
      // 시작일이 종료일과 같거나 클 경우
      if (moment(current).endOf('day') >= moment(formValues.endEpoch)) {
        setFormValues({
          ...formValues,
          startEpoch: moment(current).startOf('day'),
          endEpoch: null,
        });
        setValue('endEpoch', null);
      } else {
        setFormValues({ ...formValues, startEpoch: moment(current).startOf('day') });
      }
    } else if (type === 'endEpoch') {
      setFormValues({
        ...formValues,
        endEpoch: moment(current).hour(23).minute(59).second(59).millisecond(0),
      });
    }
  };

  // 시작일자, 종료일자 선택 불가 날짜 지정
  const handleDisableDate = (type: any, current: any) => {
    let isDisable = false;
    if (type === 'startEpoch') {
      isDisable =
        // 현재일(오늘)보다 작을때
        current < moment().startOf('day') || current > moment('2037-12-30').startOf('day');
    } else {
      isDisable =
        // 시작일자보다 작을 때
        current < moment(formValues.startEpoch).add(1, 'd') ||
        // 현재일(오늘)보다 작을때
        current < moment().startOf('day') ||
        current > moment('2037-12-31').add(1, 'd');
    }
    return isDisable;
  };

  // 1년으로 종료일 맞춤
  const setOneYear = () => {
    setFormValues({
      ...formValues,
      endEpoch: moment(formValues.startEpoch).add(1, 'y').subtract(1, 's').endOf('day'),
    });
  };

  // 라이선스 타입 변경 시
  const changeLicenseType = (name: string, data: any) => {
    setFormValues({
      ...formValues,
      [name]: data,
      startEpoch: dataInfo.startEpoch
        ? moment(dataInfo.startEpoch * 1000)
        : moment().startOf('day'),
      endEpoch: dataInfo.endEpoch
        ? moment(dataInfo.endEpoch * 1000)
        : moment().startOf('day').add(1, 'y').subtract(1, 's').endOf('day'),
    });
  };

  // 라이선스 정보 저장
  const onSaveLicense = async () => {
    try {
      const params: any = {};

      // 값 변경 여부
      let changed = isAddMode ? true : false;
      Object.keys(formValues).forEach((key: any) => {
        if (key === 'startEpoch') {
          if (
            formValues[key] &&
            moment(formValues.endEpoch).startOf('day') !== moment(dataInfo.startEpoch * 1000) &&
            formValues.licenseType !== 4
          ) {
            changed = true;
            params.startEpoch = moment(formValues.startEpoch).startOf('day').unix();
          }
        } else if (key === 'endEpoch') {
          if (
            formValues[key] &&
            moment(formValues.endEpoch).endOf('day') !== moment(dataInfo.endEpoch * 1000) &&
            formValues.licenseType !== 4
          ) {
            changed = true;
            params.endEpoch = moment(formValues.endEpoch).endOf('day').unix();
          }
        } else if (key === 'examLimitCount' || key === 'licenseCount') {
          if (
            formValues[key] &&
            (formValues.licenseType !== dataInfo.licenseType ||
              parseInt(formValues[key], 10) !== dataInfo[key])
          ) {
            changed = true;
            params[key] = parseInt(formValues[key], 10);
          }
        } else if (
          formValues[key] !== undefined &&
          formValues[key] !== null &&
          formValues[key] !== '' &&
          formValues[key] !== dataInfo[key]
        ) {
          // 변경된 항목만 파라미터에 넣어줌
          changed = true;
          params[key] = formValues[key];
        }
      });

      // 수정일 경우 라이선스번호 추가
      if (!isAddMode) {
        params.licenseNo = dataInfo.licenseNo;
      }

      // 관리자일 경우 서비스번호 추가
      if (isAdmin) {
        params.serviceNo = formValues.serviceNo;
      }

      if (changed) {
        setLoading(true);
        const response: any = await dispatch(
          isAddMode ? addLicense(params) : updateLicense(params),
        );
        if (response?.data?.error) {
          if (response.data.error[0]?.errInfo?.includes('ServiceNo')) {
            setError('serviceNo', {
              type: 'wrong',
              message: formatMessage({
                id: 'Service_14',
                defaultMessage: '잘못된 서비스번호입니다.',
              }),
            });
          } else if (response.data.error[0]?.licenseType?.[0] === 'required field') {
            setError('licenseType', {
              type: 'wrong',
              message: formatMessage({
                id: 'Vali_14',
                defaultMessage: '값이 없습니다.',
              }),
            });
          }
          setLoading(false);
        } else {
          setLoading(false);
          toggleModal(false);
        }
      } else {
        toggleModal(false);
      }
    } catch (error) {
      console.log('LicenseAddEdit onSaveLicense', error);
    }
  };

  return (
    <ModalTemplate
      className="license-add-edit-modal modal-464"
      visible={visible}
      title={
        <div className="modal-template-header">
          <div className="title">
            {formatMessage({ id: 'License_24', defaultMessage: '라이선스 정보' })}
          </div>
        </div>
      }
      onOk={handleSubmit(onSaveLicense)}
      onCancel={() => toggleModal(false)}
      okText={
        isAdmin
          ? formatMessage({ id: 'Button_21', defaultMessage: '저 장' })
          : formatMessage({ id: 'Button_24', defaultMessage: '신 청' })
      }
      cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
      loading={loading}
    >
      <Loading loading={loading} />

      {!isAdmin && (
        <>
          <div className="modal-explain-text">
            {formatMessage({
              id: 'License_51',
              defaultMessage: '라이선스 발급에 따른 안내는 이메일을 통해 진행됩니다.',
            })}
          </div>
          <div className="modal-border-box">
            <ul className="modal-item-list">
              <li>
                <div className="item-title">
                  {formatMessage({ id: 'Login_9', defaultMessage: '서비스' })}
                </div>
              </li>
              <div>{userData?.serviceName}</div>
            </ul>
            <ul className="modal-item-list">
              <li>
                <div className="item-title">
                  {formatMessage({ id: 'License_14', defaultMessage: '신청인' })}
                </div>
              </li>
              <div>{userData?.userName}</div>
            </ul>
            <ul className="modal-item-list">
              <li>
                <div className="item-title">
                  {formatMessage({ id: 'Email_1', defaultMessage: '이메일' })}
                </div>
              </li>
              <div>{userData?.userEmail}</div>
            </ul>
          </div>
        </>
      )}

      <form autoComplete="off" onSubmit={(e: any) => e.preventDefault()}>
        {/* 서비스No */}
        {!!isAdmin && (
          <>
            <div className="input-title">
              {formatMessage({ id: 'Service_8', defaultMessage: '서비스No' })}
            </div>
            <FormTextField
              name="serviceNo"
              error={errors.serviceNo}
              register={register}
              arrowPosition="top"
              validation={{
                validate: {
                  required: (value: any) => valid.required(value),
                  number: (value: any) => valid.number(value),
                },
              }}
              defaultValue={formValues.serviceNo}
              onChange={(e: any) =>
                setFormValues({ ...formValues, [e.target.name]: e.target.value })
              }
            />
          </>
        )}

        {/* 라이선스 타입 */}
        <div className="activate">
          <div className="text-field-title">
            {formatMessage({ id: 'License_18', defaultMessage: '라이선스 타입' })}
          </div>
          <FormSelectField
            name="licenseType"
            control={control}
            error={errors.licenseType}
            errorTooltip={errors.licenseType?.message}
            menuList={menuListLicense}
            defaultValue={formValues.licenseType}
            handleOnChange={(name: string, data: any) => {
              changeLicenseType(name, data);
            }}
            showArrow
          />
        </div>

        {(formValues.licenseType === 2 || formValues.licenseType === 8 
        || formValues.licenseType === 16 || formValues.licenseType === 32) && (
          <>
            <div className="text-field-title">
              {formatMessage({ id: 'Date_16', defaultMessage: '유효기간 시작일자' })}
            </div>
            <FormDatePicker
              control={control}
              name="startEpoch"
              value={formValues.startEpoch}
              error={errors.startEpoch}
              handleOnChange={handleOnChangeDate}
              handleDisableDate={handleDisableDate}
              helperText={
                !isAdmin &&
                `${formatMessage({
                  id: 'License_29',
                  defaultMessage: '로 부터 1년',
                })} (~${timeFormatFromUTCEpoch(
                  moment(formValues.startEpoch).add(1, 'y').unix(),
                  3,
                )})`
              }
            />

            {!!isAdmin && (
              <>
                <div className="text-field-title">
                  {formatMessage({ id: 'Date_17', defaultMessage: '유효기간 종료일자' })}
                </div>
                <FormDatePicker
                  control={control}
                  name="endEpoch"
                  value={formValues.endEpoch}
                  error={errors.endEpoch}
                  handleOnChange={handleOnChangeDate}
                  handleDisableDate={handleDisableDate}
                  helperText={`${formatMessage({
                    id: 'Date_24',
                    defaultMessage: '유효기간',
                  })}: ${Math.round(
                    moment.duration(formValues.endEpoch?.diff(formValues.startEpoch)).asDays(),
                  )}${formatMessage({ id: 'Date_18', defaultMessage: '일' })}`}
                  extraRight={
                    <button type="button" className="adjust-btn" onClick={setOneYear}>
                      {formatMessage({ id: 'Date_25', defaultMessage: '1년으로 종료일 맞춤' })}
                    </button>
                  }
                  arrowPosition="top"
                  validation={{
                    validate: {
                      required: (value: any) => {
                        return valid.required(value || formValues.endEpoch);
                      },
                    },
                  }}
                />
              </>
            )}
          </>
        )}

        {/* 훈련 횟수 */}
        {(formValues.licenseType === 4 || formValues.licenseType === 8 || formValues.licenseType === 32) && (
          <>
            <div className="text-field-title">
              {formatMessage({ id: 'License_11', defaultMessage: '훈련 횟수' })}
            </div>
            <FormTextField
              name="examLimitCount"
              error={errors.examLimitCount}
              register={register}
              arrowPosition="top"
              validation={{
                validate: {
                  required: (value: any) => valid.required(value),
                  number: (value: any) => valid.number(value),
                  minValue: (value: any) => valid.minValue(value, 1),
                  maxValue: (value: any) => valid.maxValue(value, 10000),
                  min: (value: any) => {
                    if (dataInfo.examUsedCount > parseInt(value, 10)) {
                      return formatMessage({
                        id: 'License_25',
                        defaultMessage: '훈련한 횟수 이상이어야 합니다.',
                      });
                    }
                    return undefined;
                  },
                },
              }}
              defaultValue={formValues.examLimitCount}
              onChange={(e: any) =>
                setFormValues({ ...formValues, [e.target.name]: e.target.value })
              }
            />
          </>
        )}

        {/* 라이선스 수량 */}
        <div className="text-field-title">
          {formatMessage({ id: 'License_26', defaultMessage: '라이선스 수량 (명)' })}
        </div>
        {isAdmin ? (
          <FormTextField
            name="licenseCount"
            error={errors.licenseCount}
            register={register}
            arrowPosition="top"
            validation={{
              validate: {
                required: (value: any) => valid.required(value),
                number: (value: any) => valid.number(value),
                minValue: (value: any) => valid.minValue(value, 1),
                maxValue: (value: any) => valid.maxValue(value, 100000),
                min: (value: any) => {
                  if (dataInfo.targetUsedCount > parseInt(value, 10)) {
                    return formatMessage({
                      id: 'License_27',
                      defaultMessage: '사용한 라이선스 수량 이상이어야 합니다.',
                    });
                  }
                  return undefined;
                },
              },
            }}
            defaultValue={formValues.licenseCount}
            onChange={(e: any) => setFormValues({ ...formValues, [e.target.name]: e.target.value })}
          />
        ) : (
          <FormTextField
            name="licenseCount"
            error={errors.licenseCount}
            register={register}
            arrowPosition="top"
            validation={{
              validate: {
                required: (value: any) => valid.required(value),
                number: (value: any) => valid.number(value),
                minValue: (value: any) => valid.minValue(value, 1),
                maxValue: (value: any) =>
                  valid.maxValue(value, (formValues.licenseType === 2 || formValues.licenseType === 16) ? 10000 : 1000),
              },
            }}
            defaultValue={formValues.licenseCount}
            onChange={(e: any) => setFormValues({ ...formValues, [e.target.name]: e.target.value })}
          />
        )}

        {!!isAdmin && (
          <div className="license-row">
            <FormSelectField
              name="approve"
              control={control}
              error={errors.approve}
              menuList={[
                {
                  key: 1,
                  value: 1,
                  text: formatMessage({ id: 'License_28', defaultMessage: '승인' }),
                },
                {
                  key: 0,
                  value: 0,
                  text: formatMessage({ id: 'Status_2', defaultMessage: '신청' }),
                },
              ]}
              defaultValue={formValues.approve}
              handleOnChange={(name: string, data: any) =>
                setFormValues({ ...formValues, [name]: data })
              }
              // defaultValue={isAddMode ? 0 : dataInfo.approve}
              showArrow
            />
            <FormSelectField
              name="licenseEnabled"
              control={control}
              error={errors.licenseEnabled}
              menuList={[
                {
                  key: 1,
                  value: 1,
                  text: formatMessage({ id: 'User_4', defaultMessage: '정상' }),
                },
                {
                  key: 0,
                  value: 0,
                  text: formatMessage({ id: 'User_19', defaultMessage: '삭제' }),
                },
              ]}
              defaultValue={formValues.licenseEnabled}
              handleOnChange={(name: string, data: any) =>
                setFormValues({ ...formValues, [name]: data })
              }
              showArrow
            />
          </div>
        )}
      </form>
    </ModalTemplate>
  );
}

export default LicenseAddEdit;
