import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Controller, useForm } from 'react-hook-form';
import { Tag, Select, Checkbox, Tooltip, Popover } from 'antd';
import {
  CloseCircleFilled,
  ExclamationCircleFilled,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import parse from 'html-react-parser';
import { Option as CommonOpt } from 'utils/commonValues';
import { getExam, getTag } from 'store/startExam';
import {
  changeSummaryState,
  eduNameCheck,
  getEduAttach,
  getEduLicense,
  getEduQuiz,
  postEdu,
} from 'store/edu';
import { getTarget } from 'store/target';
import ModalTemplate from 'components/common/ModalTemplate';
import FormTextField from 'components/common/FormTextField';
import FormDatePicker from 'components/common/FormDatePicker';
import FormSelectField from 'components/common/FormSelectField';
import Loading from 'components/common/Loading';
import { timeFormatFromUTCEpoch, epochFromDate, nowEpoch } from 'utils/commonFunctions';
import * as valid from 'utils/validation';
import ExecuteResult from './ExecuteResult';
import './StartEdu.scss';

// import prevImage from 'img/exam/startExam/btn_prev.png';
// import nextImage from 'img/exam/startExam/btn_next.png';
// import failImage from 'img/exam/startExam/ico_fail_s.png';

function StartEdu({ history }: any) {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  // const licenseData = useSelector((state: any) => state.startExam.license);
  const examData = useSelector((state: any) => state.startExam.exam);
  const tagData = useSelector((state: any) => state.startExam.tag);
  const eduData = useSelector((state: any) => {
    return {
      data: state.edu.eduAttach.data,
      quizData: state.edu.eduAttach.quizData,
      eduResult: state.edu.eduResult,
      license: state.edu.license,
    };
  });
  const userData = useSelector((state: any) => state.user.user);

  const [openModal, setOpenModal] = useState(false);
  const [summaryModal, setSummaryModal] = useState(false);
  const [summaryInfo, setSummaryInfo]: Array<any> = useState([]);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [licenseError, setLicenseError]: any = useState({});
  const [targetTagData, setTargetTagData] = useState([]);
  const [hasTarget, setHasTarget] = useState(false);
  // 선택한 진행중인 훈련들 중 가장 늦은 훈련 종료일 다음날
  const [ingExamEndDate, setIngExamEndDate]: any = useState('');
  // 연결훈련으로 선택된 훈련들의 진행 상황
  const [connectedExamStatus, setConnectedExamStatus] = useState('');
  // 진행중인 훈련에 속한 태그번호 리스트
  const [ingTags, setIngTags]: any = useState();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    errors,
    control,
  } = useForm({
    mode: 'all',
  });

  const [values, setValues]: any = useState({
    eduName: '',
    startEpoch: moment().startOf('day'),
    endEpoch: moment().endOf('day').add(1, 'd'),
    tagInfoArray: [],
    examNoArray: [],
    eduAttachNoArray: [],
    addQuiz: false,
    isInfection: false,
  });

  const LicenseString: any = {
    Duration: formatMessage({ id: 'License_7', defaultMessage: '기간 라이선스' }),
    Times: formatMessage({ id: 'License_8', defaultMessage: '횟수 라이선스' }),
    DurationTimes: formatMessage({ id: 'License_58', defaultMessage: '기간+횟수 라이선스' }),
    EduDuration:
      formatMessage({ id: 'License_60', defaultMessage: '[교육]' }) +
      formatMessage({ id: 'License_7', defaultMessage: '기간 라이선스' }),
    EduDurationTimes:
      formatMessage({ id: 'License_60', defaultMessage: '[교육]' }) +
      formatMessage({ id: 'License_58', defaultMessage: '횟수 라이선스' }),
    NotExist: formatMessage({
      id: 'License_63',
      defaultMessage: '교육라이선스가 존재하지 않습니다.',
    }),
    Insufficient: formatMessage({
      id: 'License_64',
      defaultMessage: '교육라이선스의 수량이 부족합니다.',
    }),
    NoTargetExist : formatMessage({
      id: 'License_70',
      defaultMessage: '선택된 대상이 (대상관리)메뉴에서 삭제되어 교육을 실시할 수 없습니다.',
    }),
  };

  if (CommonOpt.isJapan === 1) {
    LicenseString.Duration =
      formatMessage({ id: 'License_61', defaultMessage: '[훈련]' }) +
      formatMessage({ id: 'License_7', defaultMessage: '기간 라이선스' });
    LicenseString.Times =
      formatMessage({ id: 'License_61', defaultMessage: '[훈련]' }) +
      formatMessage({ id: 'License_8', defaultMessage: '횟수 라이선스(무제한)' });
    LicenseString.DurationTimes =
      formatMessage({ id: 'License_61', defaultMessage: '[훈련]' }) +
      formatMessage({ id: 'License_58', defaultMessage: '횟수 라이선스' });
    LicenseString.EduDuration =
      formatMessage({ id: 'License_60', defaultMessage: '[교육]' }) +
      formatMessage({ id: 'License_7', defaultMessage: '기간 라이선스' });
    LicenseString.EduDurationTimes =
      formatMessage({ id: 'License_60', defaultMessage: '[교육]' }) +
      formatMessage({ id: 'License_58', defaultMessage: '횟수 라이선스' });
    LicenseString.NotExist = formatMessage({
      id: 'License_63',
      defaultMessage: '교육라이선스가 존재하지 않습니다.',
    });
    LicenseString.Insufficient = formatMessage({
      id: 'License_64',
      defaultMessage: '교육라이선스의 수량이 부족합니다.',
    });
    LicenseString.NoTargetExist = formatMessage({
      id: 'License_70',
      defaultMessage: '선택된 대상이 (대상관리)메뉴에서 삭제되어 교육을 실시할 수 없습니다.',
    });
  }

  const { Option } = Select;

  useEffect(() => {
    // 라이선스 에러 팝업 제거
    if (licenseError.error || licenseError.data) {
      setLicenseError({});
    }
  }, [values, step]);

  useEffect(() => {
    // 대상자 태그 리스트에 보여줄 태그 데이터 추출
    if (values.isInfection) {
      const infectedTagData: any = [];
      examData?.forEach((exam: any) => {
        if (
          values.examNoArray.includes(exam.examNo) &&
          exam.tagInfection &&
          exam.examEndEpoch <= nowEpoch()
        ) {
          exam.tagInfection.forEach((item: any) => {
            tagData?.forEach((tag: any) => {
              if (tag.tagNo === item.tagNo) {
                infectedTagData.push({ ...tag, ...item, ...{ examName: exam.examName } });
              }
            });
          });
        }
      });
      if (!values.examNoArray.length) {
        setValues({ ...values, isInfection: false, tagInfoArray: [] });
      }
      setTargetTagData(infectedTagData);
    } else {
      setTargetTagData(tagData);
    }
  }, [JSON.stringify(values.examNoArray), values.isInfection, examData, tagData]);

  useEffect(() => {
    compareDate();
  }, [values.isInfection, values.examNoArray]);

  // 대상자 조회
  const onGetTarget = async () => {
    try {
      setLoading(true);

      const params = {
        tagNoArray: 'all-tag',
        offset: 0,
        limit: 5,
        multiType: 'or',
        refresh: true,
      };

      const response: any = await dispatch(getTarget(params));
      if (response.data?.list?.length > 0) {
        setHasTarget(true);
      }
      setLoading(false);
    } catch (error) {
      console.log('StartEdu onGetTarget', error);
    }
  };

  // 대상자 태그 조회
  const onGetTag = async () => {
    try {
      setLoading(true);
      const response: any = await dispatch(getTag());
      if (response?.data?.list.length > 0) {
        setLoading(false);
      }
    } catch (error) {
      console.log('StartEdu onGetTag', error);
    }
  };

  // 교육 컨텐츠 조회
  const onGetEduAttach = async () => {
    try {
      setLoading(true);
      const params = {
        serviceNo: userData.serviceNo,
      };
      const response: any = await dispatch(getEduAttach(params));
      if (response?.data?.list.length > 0) {
        setLoading(false);
      }
    } catch (error) {
      console.log('StartEdu onGetEduAttach', error);
    }
  };

  // 훈련 조회
  const onGetExam = async () => {
    try {
      setLoading(true);

      // 연결훈련에서 선택한 훈련 번호 리스트 보내기
      await dispatch(getExam());
      setLoading(false);
    } catch (error) {
      console.log('StartEdu onGetExam', error);
    }
  };

  // 교육 퀴즈 조회
  // const onGetEduQuiz = async () => {
  //   try {
  //     setLoading(true);
  //     const params = {
  //       eduAttachNo: values.eduAttachNo,
  //     };
  //     const response: any = await dispatch(getEduQuiz(params));
  //     if (response?.data?.list.length > 0) {
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     console.log('StartEdu onGetEduQuiz', error);
  //   }
  // };

  // epoch 형식을 YYYY-mm-dd 형식으로 변경
  const changeEpoch = (epoch: number) => {
    return timeFormatFromUTCEpoch(epoch, 3);
  };

  // 라이선스 현황 컴포넌트 생성 및 반환
  const createLicenseStatus = (licenseInfo: Array<any>) => {
    const licenseComponent: any = [];

    const licenseData: any = {
      1: { type: LicenseString.Duration },
      2: { type: LicenseString.Duration },
      4: { type: LicenseString.Times },
      8: { type: LicenseString.DurationTimes },
      16: { type: LicenseString.EduDuration },
      32: { type: LicenseString.EduDurationTimes },
    };

    licenseInfo?.forEach((license: any) => {
      const { licenseType, startEpoch, endEpoch, limitInfo, examLimitCount, licenseNo } = license;

      const period = `${changeEpoch(startEpoch)} ~ ${changeEpoch(endEpoch)}`;

      const remainPeriod = `(${formatMessage({
        id: 'Period_2',
        defaultMessage: '남은 기간',
      })}: ${changeEpoch(endEpoch)})`;

      let limitCount = 0;
      if (limitInfo) {
        limitCount = Array.isArray(limitInfo) ? limitInfo.length : Object.keys(limitInfo).length;
      }

      const times = `${formatMessage({
        id: 'License_67',
        defaultMessage: '사용 횟수',
      })}  ${limitCount} / ${examLimitCount}${formatMessage({
        id: 'License_13',
        defaultMessage: '회',
      })}`;

      const remainTimes = `(${formatMessage({
        id: 'License_12',
        defaultMessage: '잔여 횟수',
      })}: ${examLimitCount - limitCount}${formatMessage({
        id: 'License_13',
        defaultMessage: '회',
      })})`;

      licenseComponent.push(
        <div className="result-license-wrap" key={licenseNo}>
          <div className="result-license-title">{`· ${licenseData[licenseType]?.type || ''}`}</div>
          <div className="result-license-content">
            <div className="license-number">{`No.${licenseNo} `}</div>
            {(licenseType === 1 || licenseType === 2 || licenseType === 16) && (
              <div>
                <div>{period}</div>
                {CommonOpt.isJapan !== 1 && <div className="point-text">{remainPeriod}</div>}
              </div>
            )}
            {(licenseType === 4 || licenseType === 32) && (
              <div>
                <div>{times}</div>
                {CommonOpt.isJapan !== 1 && <div className="point-text">{remainTimes}</div>}
              </div>
            )}
            {licenseType === 8 && (
              <div>
                <div>{period}</div>
                {CommonOpt.isJapan !== 1 && <div className="point-text">{remainPeriod}</div>}
                <div>{times}</div>
                {CommonOpt.isJapan !== 1 && <div className="point-text">{remainTimes}</div>}
              </div>
            )}
          </div>
        </div>,
      );
    });

    return licenseComponent;
  };

  // 전체 대상자 수 확인 위한 라이선스 검증
  const onCheckEduLicense = async () => {
    setLoading(true);
    try {
      const { uniqueTarget, uniqueTag } = makeUniqueTargets();
      const targetParams: any = { tagNoArray: JSON.stringify(Array.from(uniqueTag)) };
      if (values.isInfection) {
        // 종료된 훈련: 대상자번호+태그번호, 종료안된 훈련: 태그번호
        targetParams.targetNoArray = JSON.stringify(Array.from(uniqueTarget));
        targetParams.tagNoArray = JSON.stringify(Array.from(ingTags));
        targetParams.infectionTagArray = JSON.stringify(Array.from(uniqueTag));
      }

      const params = {
        serviceNo: userData.serviceNo,
        ...(values.isAllTarget ? {} : targetParams),
        ...(values.isAllTarget ? {} : { examNoArray: JSON.stringify(values.examNoArray) }),
        startEpoch: moment(values.startEpoch).unix().toString(),
      };

      const response: any = await dispatch(getEduLicense(params));
      if (response.data?.isValidLicense) {
        showSummaryPopup(response.data);
      } else {
        checkError(response.data);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  // 교육 실행 후 오류 사항 확인
  const checkError = (data: any) => {
    const { list, error, isValidLicense } = data;
    const errorMessages: any = {
      'Duplicate Edu': {
        target: 'eduName',
        text: formatMessage({ id: 'Edu_39', defaultMessage: '이미 사용중인 교육명입니다.' }),
      },
      'No education Attach': {
        target: 'eduAttachNoArray',
        text: formatMessage({ id: 'Valid_14', defaultMessage: '값이 없습니다.' }),
      },
    };

    if (error && Array.isArray(error)) {
      setStep(1);
      const errorInfo = errorMessages[error[0]?.errInfo];
      setError(errorInfo.target, {
        type: errorInfo.target || 'eduName',
        message: errorInfo.text || error[0]?.errInfo,
      });
    } else if (isValidLicense === 0) {
      // 라이선스 에러
      setLicenseError({
        list: list || [],
        error: error,
        isValidLicense: isValidLicense,
        data: data,
      });
    } else if (list) {
      const { attachNameArray, licenseInfo } = list[0];
      // 교육명, 교육기간, 교육대상
      const result: any = [...summaryInfo];
      result.push({
        text: formatMessage({ id: 'Edu_4', defaultMessage: '교육 템플릿' }),
        value: parse(`<div>· ${attachNameArray.join('</div><div>· ')}`),
      });

      result.push({
        text: formatMessage({ id: 'License_2', defaultMessage: '라이선스 현황' }),
        value: createLicenseStatus(licenseInfo),
      });
      setSummaryInfo(result);
      setStep(2);
    }
  };

  const makeUniqueTargets = () => {
    const { tagInfoArray, isInfection } = values;
    // 대상자 태그 정보 값
    const uniqueTarget = new Set();
    const uniqueTag = new Set();

    targetTagData.forEach((tag: any) => {
      const { tagNo, tagInfection, targetCount, examName, tagName } = tag;
      const originTag = `${tagNo},${targetCount},${tagName}`;
      const infectionTag = `${tagNo},${tagInfection?.length},${examName}`;

      const compareTag = isInfection ? infectionTag : originTag;
      if (tagInfoArray.includes(compareTag)) {
        uniqueTag.add(tagNo);

        if (isInfection) {
          tagInfection.forEach((targetNo: number) => uniqueTarget.add(targetNo));
        }
      }
    });

    return { uniqueTarget, uniqueTag };
  };

  // 교육 실시
  const onStartEdu = async () => {
    setLoading(true);
    const {
      eduName,
      eduAttachNoArray,
      startEpoch,
      endEpoch,
      tagInfoArray,
      examNoArray,
      isInfection,
      isAllTarget,
    } = values;

    // 대상자 태그 값 재확인
    let errorKey = '';
    Object.keys(values).forEach((key: string) => {
      if (
        key === 'tagInfoArray' &&
        values[key]?.length < 1 &&
        !values.isAllTarget &&
        connectedExamStatus === 'end'
      ) {
        errorKey = key;
      }
    });

    if (errorKey) {
      setLoading(false);
      setError(errorKey, { type: 'required' });
      return;
    }

    const paramEduAttach = eduAttachNoArray
      ? eduAttachNoArray.map((item: any) => parseInt(item.split(',')[0], 10))
      : [];

    try {
      const params: any = {
        eduName: eduName,
        eduAttachNoArray: JSON.stringify(paramEduAttach),
        startEpoch: epochFromDate(startEpoch),
        endEpoch: epochFromDate(endEpoch),
        isInfection: isInfection,
        isAllTarget: isAllTarget,
      };

      // 연결훈련 선택 시
      if (examNoArray.length > 0) {
        params.examNoArray = JSON.stringify(examNoArray);
      }

      const { uniqueTarget, uniqueTag } = makeUniqueTargets();
      params.tagNoArray = JSON.stringify(Array.from(uniqueTag));
      if (values.isInfection) {
        // 종료된 훈련: 대상자번호+태그번호, 종료안된 훈련: 태그번호
        params.targetNoArray = JSON.stringify(Array.from(uniqueTarget));
        params.tagNoArray = JSON.stringify(Array.from(ingTags));
        params.infectionTagArray = JSON.stringify(Array.from(uniqueTag));
      }

      // 모든 퀴즈 값을 하나의 배열로 통합
      const quizNoList: any = [];
      eduData.quizData.forEach((data: any) => {
        const item = `quizNoArray${data.fileNo}`;
        values[item]?.forEach((quizNo: number) => {
          quizNoList.push(quizNo);
        });
      });

      // 퀴즈 선택 시
      if (quizNoList.length > 0) {
        params.quizNoArray = JSON.stringify(quizNoList);
      }

      const response: any = await dispatch(postEdu(params));
      checkError(response.data);
      setLoading(false);
    } catch (error) {
      console.log('StartEdu onStartEdu', error);
    }
  };

  // 교육결과 메뉴로 이동
  const goToEduResult = () => {
    dispatch(
      changeSummaryState({
        key: 'selectedEduNo',
        value: eduData?.eduResult?.list[0]?.eduNo || 'all',
      }),
    );
    history.push('/eduresult');
    toggleModal(false);
  };

  // 모달 열기/닫기 이벤트
  const toggleModal = (isOpen: boolean) => {
    if (isOpen) {
      // 모달 열기
      setOpenModal(true);
      onGetTag();
      onGetEduAttach();
      onGetExam();
      onGetTarget();
    } else {
      // 모달 닫기
      clearErrors();
      setStep(1);
      setOpenModal(false);
      setValues({
        eduName: '',
        eduAttachNoArray: [],
        startEpoch: moment().startOf('day'),
        endEpoch: moment().endOf('day').add(1, 'd'),
        tagInfoArray: [],
        examNoArray: [],
        addQuiz: false,
        isInfection: false,
        hasTarget: false,
      });
      setSummaryInfo([]);
    }
  };

  // 이전 클릭 이벤트
  // const prevStep = () => {
  //   // 이전 단계로 이동
  //   setStep(1);
  // };

  // 다음, 실행 버튼 클릭 이벤트
  // const nextStep = () => {
  //   if (values.addQuiz && step === 1) {
  //     // 퀴즈 선택 화면으로 이동
  //     onGetEduQuiz();
  //     setStep(2);
  //   } else {
  //     // 교육 실시
  //     onStartEdu();
  //   }
  // };

  // 교육 실행 전 요약 팝업에 띄울 정보 저장

  const showSummaryPopup = (licenseData: any) => {
    const {
      startEpoch,
      endEpoch,
      eduName,
      eduAttachNoArray,
      examNoArray,
      tagInfoArray,
      isInfection,
    } = values;
    const eduForm = [];
    // const { startEpoch, endEpoch } = licenseData?.list?.[0];

    // 감염된 훈련 대상자 수
    const getCountFromExam = (exam: any) =>
      examNoArray.includes(exam.examNo) ? exam.infection : 0;
    const infectionTargetCount = examData.reduce(
      (acc: number, exam: any) => acc + getCountFromExam(exam),
      0,
    );

    // 감염된 대상자 수가 없을 경우 교육 실행 불가
    if (values.isInfection && connectedExamStatus === 'end' && infectionTargetCount === 0) {
      setError('examNoArray', {
        type: 'notExist',
        message: formatMessage({
          id: 'Edu_123',
          defaultMessage: '감염/정보유출된 훈련 대상자가 없습니다.',
        }),
      });
      return;
    }
    const targetCountMessage = `${licenseData?.targetAllCount}${formatMessage({
      id: 'StartExam_35',
      defaultMessage: '명',
    })}`;

    setSummaryModal(true);

    eduForm.push({
      text: formatMessage({ id: 'Edu_28', defaultMessage: '교육명' }),
      value: eduName,
    });
    eduForm.push({
      text: formatMessage({ id: 'Period_4', defaultMessage: '교육 기간' }),
      value: `${moment(startEpoch).format('YYYY-MM-DD')} ~ ${moment(endEpoch).format(
        'YYYY-MM-DD',
      )} ${
        moment(startEpoch) > moment() && !CommonOpt.isJapan
          ? formatMessage({ id: 'StartExam_59', defaultMessage: '(예약됨)' })
          : ''
      }`,
    });
    if (!CommonOpt.isJapan) {
      eduForm.push({
        text: formatMessage({ id: 'Edu_110', defaultMessage: '교육 대상' }),
        value: targetCountMessage,
      });
    }
    setSummaryInfo(eduForm);
  };

  const compareDate = () => {
    let lastEndEpoch = nowEpoch();
    // 진행 중인 훈련
    let hasOngoing = false;
    // 종료된 훈련
    let hasEnded = false;
    const tempTags = new Set();

    if (values.isInfection) {
      examData.forEach((item: any) => {
        if (values.examNoArray.includes(item.examNo)) {
          if (item.examEndEpoch > nowEpoch()) {
            hasOngoing = true;
            const tagNumbers = item.examTagTarget
              ? item.examTagTarget?.replace(/[{}]/g, '').trim().split(',').map(Number)
              : [];
            tagNumbers.forEach((item: number) => {
              tempTags.add(item);
            });
          } else {
            hasEnded = true;
          }
          lastEndEpoch = Math.max(item.examEndEpoch, lastEndEpoch);
        }
      });
      setIngTags(tempTags);

      // 연결훈련 상태 판단
      if (hasOngoing && hasEnded) {
        // 진행 중인 훈련과 종료된 훈련이 섞여 있음
        setConnectedExamStatus('mix');
      } else if (hasOngoing) {
        // 진행 중인 훈련만 있음
        setConnectedExamStatus('ongoing');
      } else if (hasEnded) {
        // 종료된 훈련만 있음
        setConnectedExamStatus('end');
      }

      const tomorrow = moment(lastEndEpoch * 1000)
        .startOf('day')
        .add(1, 'd');

      const isIngExam = lastEndEpoch > nowEpoch();

      setValues({
        ...values,
        startEpoch: isIngExam ? tomorrow : values.startEpoch,
        endEpoch: isIngExam ? null : values.endEpoch,
      });
      setValue('endEpoch', isIngExam ? null : values.endEpoch);
      setIngExamEndDate(isIngExam ? tomorrow : '');
    } else {
      const endDate = values.startEpoch >= values.endEpoch ? null : values.endEpoch;
      setValues({
        ...values,
        startEpoch: values.startEpoch,
        endEpoch: endDate,
      });
      setValue('endEpoch', endDate);
      setIngExamEndDate('');
      setConnectedExamStatus('');
    }
  };

  // 시작일자, 종료일자 선택 불가 날짜 지정
  const handleDisableDate = (type: any, current: any) => {
    let isDisable = false;
    if (type === 'startEpoch') {
      isDisable = ingExamEndDate
        ? current < values.startEpoch // 교육 시작일(훈련 종료 다음날)보다 작을 때
        : current < moment().startOf('day') || current > moment('2037-12-30').startOf('day'); // 현재일(오늘)보다 작을때
    } else if (type === 'endEpoch') {
      isDisable =
        // 시작일자보다 작을 때
        current < moment(values.startEpoch).add(1, 'd') ||
        // 현재일(오늘)보다 작을때
        current < moment().startOf('day') ||
        current > moment('2037-12-31').add(1, 'd');
    }
    return isDisable;
  };

  // 시작일자, 종료일자 선택 시
  const handleOnChangeDate = (type: string, current: any) => {
    if (type === 'startEpoch') {
      // 시작일이 종료일과 같거나 클 경우
      if (moment(current).endOf('day') >= moment(values.endEpoch)) {
        setValues({
          ...values,
          startEpoch: ingExamEndDate ? values.startEpoch : moment(current).startOf('day'),
          endEpoch: null,
        });
        setValue('endEpoch', null);
      } else {
        setValues({ ...values, startEpoch: moment(current).startOf('day') });
      }
    } else if (type === 'endEpoch') {
      const endDate = moment(current).hour(23).minute(59).second(59).millisecond(0);
      setValues({
        ...values,
        endEpoch: endDate,
      });
      setValue('endEpoch', endDate);
    }
  };

  // 드롭다운 리스트 선택 이벤트
  const handleSelectList = (name: string, data: any) => {
    setValues({
      ...values,
      [name]: data,
    });
  };

  // 교육 컨텐츠 선택 이벤트
  const handleAttachSelectList = (name: string, value: any) => {
    if (value.some((item: any) => item.split(',')[1] === '1')) {
      // contentType이 1일 때는 1개만 선택 가능, 다른 유형과 혼합 불가.
      if (value.length < 2) {
        setValues({
          ...values,
          eduAttachNoArray: value,
        });
      }
    } else if (value.length < 4) {
      // contentType이 1이 아닐 때는 3개까지 선택 가능, 다른 유형과 혼합 가능.
      setValues({
        ...values,
        eduAttachNoArray: value,
      });
    }
  };

  // 태그 삭제(X 버튼 클릭) 이벤트
  const handleClearTag = (name: any, exceptTagNo: any) => {
    const selectedValue = values[name].filter((tagNo: any) => tagNo !== exceptTagNo);
    setValues({
      ...values,
      [name]: selectedValue,
      ...(selectedValue.length === 0 ? { isInfection: false } : ''),
    });
  };

  // 교육 컨텐츠 태그 삭제(X 버튼 클릭) 이벤트
  const handleClearAttachTag = (name: any, exceptTagNo: any) => {
    const selectedValue = values[name].filter((item: any) => {
      const selectNo = parseInt(item.split(',')[0], 10);
      return selectNo !== exceptTagNo;
    });
    setValues({
      ...values,
      [name]: selectedValue,
    });
  };

  // 대상자 태그
  const tagRender = (props: any, name: any) => {
    let tagComponent = null;
    const [propsTagNo, propsCount, propsExamName] = props.value.split(',');
    const currentTagData = targetTagData.filter((tag: any) => {
      const origin = `${tag.examName}/${tag.tagNo}`;
      const select = `${propsExamName}/${propsTagNo}`;
      return values.isInfection ? origin === select : tag.tagNo === parseInt(propsTagNo, 10);
    })[0];

    if (currentTagData) {
      const { tagName, color, tagNo } = currentTagData;
      const targetTagName = values.isInfection ? `${propsExamName} / ${tagName}` : tagName;
      tagComponent = (
        <Tag closable className={`color-tag tag-label-${color}`}>
          {`${targetTagName} - ${propsCount}${formatMessage({
            id: 'StartExam_35',
            defaultMessage: '명',
          })}`}
          <CloseCircleFilled onClick={() => handleClearTag(name, props.value)} />
        </Tag>
      );
    }
    return tagComponent;
  };

  // 태그 목록 컴포넌트
  let tagListComponent = null;
  const tagOption: any = [];

  // 등록된 태그가 있을 경우
  if (targetTagData?.length > 0) {
    // 대상자 태그 리스트
    const tagList = targetTagData.filter((tag: any) => {
      const originTag = `${tag.tagNo},${tag.targetCount},${tag.tagName}`;
      const infectionTag = `${tag.tagNo},${tag.tagInfection?.length},${tag.examName}`;
      return !values.tagInfoArray.includes(values.isInfection ? infectionTag : originTag);
    });

    tagList.forEach((tag: any) => {
      const { tagNo, tagName, color, targetCount, tagInfection, examName } = tag;
      const count = values.isInfection ? tagInfection?.length : targetCount;

      //  드롭다운 메뉴
      tagOption.push(
        <Option
          // key={`exam-tag-${tagNo}`}
          value={`${tagNo},${count},${examName || tagName}`}
          className={`exam-tag ${count < 1 ? 'disabled' : ''}`}
        >
          <div className="tag-select-option flex">
            <div className={`tag-circle tag-${color}`} />
            <div className="tag-name">
              {values.isInfection ? `${tag.examName} / ${tagName}` : tagName}
            </div>
            {values.isInfection && (
              <div className="tag-infection-text">
                {formatMessage({ id: 'Edu_124', defaultMessage: '감염/유출 대상자' })}
              </div>
            )}
            <div className="tag-count">
              {count}
              {formatMessage({
                id: 'StartExam_35',
                defaultMessage: '명',
              })}
            </div>
          </div>
        </Option>,
      );
    });

    // 태그별 대상자 수 합계
    const totalTargetCount = targetTagData.reduce(
      (acc: number, tag: any) => acc + tag.targetCount,
      0,
    );

    tagListComponent = (
      <FormSelectField
        className={!values.isInfection && totalTargetCount < 1 && !watch().isAllTarget && 'warning'}
        name="tagInfoArray"
        control={control}
        error={errors.tagInfoArray}
        option={tagOption}
        handleOnChange={handleSelectList}
        mode="multiple"
        showArrow
        tagRender={(props: any) => tagRender(props, 'tagInfoArray')}
        placeholder={
          totalTargetCount < 1
            ? formatMessage({ id: 'Tag_24', defaultMessage: '태그에 할당된 대상자가 없습니다.' })
            : formatMessage({ id: 'Edu_43', defaultMessage: '교육 실시할 태그를 선택하세요.' })
        }
        validation={{
          validate: {
            required: (value: any) =>
              valid.required(
                value?.length > 0 ||
                  values.tagInfoArray?.length > 0 ||
                  watch().isAllTarget ||
                  (connectedExamStatus !== 'end' && value?.length < 1),
              ),
          },
        }}
        value={values.tagInfoArray}
        filterOption={(input: any, option: any) => {
          return option.children.props.children[1].props.children
            .toLowerCase()
            .includes(input.toLowerCase());
        }}
        disabled={watch().isAllTarget}
      />
    );
  } else {
    const message = {
      ongoing: {
        mainText: formatMessage({
          id: 'Edu_158',
          defaultMessage: '대상자 태그를 선택할 수 없습니다.',
        }),
        subText: formatMessage({
          id: 'Edu_159',
          defaultMessage:
            "미종료 훈련만 선택하여 '감염/정보유출자'를 교육 대상으로 설정한 경우에는 다른 대상자 태그는 선택할 수 없습니다.",
        }),
      },
      notExist: {
        mainText: formatMessage({ id: 'Tag_25', defaultMessage: '대상자 태그가 없습니다.' }),
        subText: formatMessage({
          id: 'Tag_26',
          defaultMessage:
            '대상자를 추가하고 태그를 부여하면 태그를 이용하여 여러 대상자를 한번에 선택할 수 있습니다.',
        }),
      },
    };
    const type = connectedExamStatus === 'ongoing' ? 'ongoing' : 'notExist';

    // 등록된 태그가 없을 경우
    tagListComponent = (
      <div className={`no-tag-area ${connectedExamStatus === 'ongoing' ? 'grey' : ''}`}>
        <div className="no-tag-main-text">
          <ExclamationCircleFilled />
          {message[type].mainText}
        </div>
        <div className="no-tag-sub-text">{message[type].subText}</div>
      </div>
    );
  }

  // 퀴즈 드롭다운 메뉴
  // const makeQuizOption = (fileNo: number) => {
  //   const eduQuizMenu: any = [];
  //   if (eduData?.quizData?.length > 0) {
  //     const remainData: any = [];
  //     eduData?.quizData?.forEach((data: any) => {
  //       if (data.quizInfo && data.fileNo === fileNo) {
  //         data.quizInfo.forEach((quiz: any) => {
  //           if (!values[`quizNoArray${fileNo}`]?.includes(quiz.quizNo)) {
  //             remainData.push(quiz);
  //           }
  //         });
  //       }
  //     });

  //     remainData?.forEach((quiz: any) => {
  //       eduQuizMenu.push(
  //         <Option value={quiz.quizNo} key={quiz.quizContent}>
  //           {quiz.quizContent}
  //         </Option>,
  //       );
  //     });

  //     return eduQuizMenu;
  //   }
  //   return <></>;
  // };

  // 퀴즈 태그
  // const quizTagRender = (props: any, name: any) => {
  //   let tagComponent = null;
  //   let remainData: any = {};

  //   eduData?.quizData?.forEach((data: any) => {
  //     data?.quizInfo?.forEach((quiz: any) => {
  //       if (quiz.quizNo === props.value) {
  //         remainData = quiz;
  //       }
  //     });
  //   });

  //   if (Object.keys(remainData).length > 0) {
  //     const { quizNo, quizContent } = remainData;
  //     tagComponent = (
  //       <Tag closable className="default-tag" onClose={() => handleClearTag(name, quizNo)}>
  //         {quizContent}
  //       </Tag>
  //     );
  //   }
  //   return tagComponent;
  // };

  // 교육 컨텐츠 드롭다운 메뉴
  const eduAttachMenu: any = [];
  if (eduData.data?.length > 0) {
    const authInfo = [
      { authText: formatMessage({ id: 'User_1', defaultMessage: '사용자' }), authColor: 'user' },
      {
        authText: formatMessage({ id: 'Template_14', defaultMessage: '기 본' }),
        authColor: 'system',
      },
    ];
    const contentTypeInfo = [
      { contentText: 'MP4(multiple)', contentColor: 'down' },
      { contentText: 'YouTube', contentColor: 'red' },
      { contentText: 'PDF', contentColor: 'cure' },
      { contentText: 'MP4(single)', contentColor: 'down' },
    ];

    const selectNoArray = values.eduAttachNoArray.map((item: any) =>
      parseInt(item.split(',')[0], 10),
    );
    const remainData = eduData.data?.filter((edu: any) => !selectNoArray.includes(edu.eduAttachNo));
    remainData?.forEach((item: any) => {
      const { authText, authColor } = authInfo[item.isBasic];
      const { contentText, contentColor } = contentTypeInfo[item.contentType - 1];

      eduAttachMenu.push(
        <Option key={item.eduAttachNo} value={`${item.eduAttachNo},${item.contentType}`}>
          <Tag className={`edu-attach-tag color-temp-${authColor}`}>{authText}</Tag>
          <Tag className={`edu-attach-tag color-border-${contentColor}`}>{contentText}</Tag>
          {item.attachName}
        </Option>,
      );
    });
  }

  // 교육 컨텐츠 태그
  const eduAttachTagRender = (props: any, name: any) => {
    let tagComponent = null;
    const selectNo = parseInt(props.value.split(',')[0], 10);
    const remainData = eduData?.data?.filter((data: any) => data.eduAttachNo === selectNo)[0];

    if (remainData) {
      const { eduAttachNo, attachName } = remainData;
      tagComponent = (
        <Tag
          closable
          className="default-tag"
          onClose={() => handleClearAttachTag(name, eduAttachNo)}
        >
          <span className="ellipsis" title={attachName}>
            {attachName}
          </span>
        </Tag>
      );
    }
    return tagComponent;
  };
  const examTypeString = (exam: any) => {
    let examTypeString = 'warning';
    if (exam && exam?.attachData) {
      if (exam.examType === 1 && exam.attachData?.attachExamType === 1) {
        examTypeString = 'warning';
      } else if (exam.examType === 1 && exam.attachData?.attachExamType === 2) {
        examTypeString = 'file';
      } else if (exam.examType === 1 && exam.attachData?.attachExamType === 3) {
        examTypeString = 'research';
      } else if (exam.examType === 4) {
        examTypeString = 'phishing';
      }
    }
    return examTypeString;
  };

  // 연결훈련 드롭다운 메뉴
  const examMenu: any = [];
  if (examData?.length > 0) {
    const remainData = examData.filter((exam: any) => !values.examNoArray.includes(exam.examNo));
    remainData?.forEach((exam: any) => {
      examMenu.push(
        <Option value={exam.examNo} key={exam.examName}>
          <div className="exam-select-option flex">
            <img
              className="exam-type-circle"
              src={`/img/exam/startExam/temp_${examTypeString(exam) || 'none'}_36.png`}
              alt="icon"
            />
            <div className="exam-info-area">
              <div className="exam-info-title">{`(No: ${exam.examNo}) ${exam.examName}`}</div>
              <div className="exam-info">
                {`${formatMessage({
                  id: 'Period_3',
                  defaultMessage: '훈련 기간',
                })}: ${timeFormatFromUTCEpoch(exam.examStartEpoch, 3)} ~ ${timeFormatFromUTCEpoch(
                  exam.examEndEpoch,
                  3,
                )}`}
                {/* 유출/감염률 */}
                {` · ${
                  exam.examType === 4
                    ? formatMessage({ id: 'Infection_26', defaultMessage: '정보 유출' })
                    : formatMessage({ id: 'Infection_1', defaultMessage: '감염' })
                }:`}
                {` ${exam.infection || 0} / ${exam.target || 0}${formatMessage({
                  id: 'StartExam_35',
                  defaultMessage: '명',
                })}`}
              </div>
            </div>
          </div>
        </Option>,
      );
    });
  }

  // 연결훈련 태그
  const examTagRender = (props: any, name: any) => {
    let tagComponent = null;
    const remainData = examData.filter((exam: any) => exam.examNo === props.value)[0];
    if (remainData) {
      const { examNo, examName } = remainData;
      tagComponent = (
        <Tag closable className="default-tag" onClose={() => handleClearTag(name, examNo)}>
          <span className="ellipsis" title={examName}>
            {examName}
          </span>
        </Tag>
      );
    }
    return tagComponent;
  };

  // 라이선스 에러 확인
  let licenseContent = null;
  if (licenseError?.isValidLicense === 0) {
    if (licenseError.error === 'noLicense') {
      licenseContent = <div>{LicenseString.NotExist}</div>;
    }else if (licenseError.error === 'noEduTarget') {
      licenseContent = <div>{LicenseString.NoTargetExist}</div>;
    } else if (licenseError.error === 'noReserve') {
      licenseContent = (
        <div>
          {formatMessage({
            id: 'License_23',
            defaultMessage: '교육 시작일을 라이선스 유효기간 이후로 예약할 수 없습니다.',
          })}
        </div>
      );
    } else {
      let periodCount = 0;
      let timesCount = 0;
      let timesperiodCount = 0;
      const { periodCnt, timesCnt } = licenseError.data;
      licenseError.list.forEach((license: any) => {
        const {
          licenseType,
          licenseNo,
          remainCount,
          licenseCount,
          startEpoch,
          endEpoch,
          examLimitCount,
        } = license;
        // licenseType => isJapan이 아니면 훈련 라이선스로 통합 사용
        // isJapan의 경우 교육 라이선스 16(기간), 32(기간+횟수) 를 사용해야함.
        if (CommonOpt.isJapan === 1) {
          if (licenseType === 16) {
            periodCount += 1;
          } else if (licenseType === 32) {
            timesperiodCount += 1;
          }
        } else if (CommonOpt.isJapan !== 1) {
          if (licenseType === 1 || licenseType === 2) {
            periodCount += 1;
          } else if (licenseType === 4) {
            timesCount += 1;
          } else if (licenseType === 8) {
            timesperiodCount += 1;
          }
        }

        licenseContent = (
          <div>
            <div>{LicenseString.Insufficient}</div>
            <div className="license-status-row">
              {[1, 2].includes(licenseType) && (
                <div>
                  {CommonOpt.isJapan !== 1 && periodCount === 1 && (
                    <div className="license-name">
                      {`· ${LicenseString.Duration} (${periodCnt}${formatMessage({
                        id: 'StartExam_35',
                        defaultMessage: '명',
                      })} ${formatMessage({
                        id: 'License_9',
                        defaultMessage: '부족',
                      })})`}
                    </div>
                  )}
                  <div className="license-detail">
                    <div className="license-number">No.{licenseNo}</div>
                    <div className="license-target">{` ${
                      licenseCount - remainCount
                    }/${licenseCount}${formatMessage({
                      id: 'StartExam_35',
                      defaultMessage: '명',
                    })}`}</div>
                    <div className="license-period">{` ${changeEpoch(startEpoch)} ~ ${changeEpoch(
                      endEpoch,
                    )}`}</div>
                  </div>
                </div>
              )}
              {licenseType === 4 && (
                <div>
                  {CommonOpt.isJapan !== 1 && timesCount === 1 && (
                    <div className="license-name">
                      {`· ${LicenseString.Times} (${timesCnt}${formatMessage({
                        id: 'StartExam_35',
                        defaultMessage: '명',
                      })} ${formatMessage({
                        id: 'License_9',
                        defaultMessage: '부족',
                      })})`}
                    </div>
                  )}
                  <div className="license-detail">
                    <div>No.{licenseNo}</div>
                    <div>
                      {` ${licenseCount}${formatMessage({
                        id: 'StartExam_35',
                        defaultMessage: '명',
                      })}`}
                    </div>
                    <div>
                      {` ${examLimitCount - remainCount}/${examLimitCount}${formatMessage({
                        id: 'StartExam_35',
                        defaultMessage: '회',
                      })}`}
                    </div>
                  </div>
                </div>
              )}
              {[8].includes(licenseType) && (
                <div>
                  {CommonOpt.isJapan !== 1 && timesperiodCount === 1 && (
                    <div className="license-name">
                      {`· ${LicenseString.DurationTimes} (${timesCnt}${formatMessage({
                        id: 'StartExam_35',
                        defaultMessage: '명',
                      })} ${formatMessage({
                        id: 'License_9',
                        defaultMessage: '부족',
                      })})`}
                    </div>
                  )}
                  <div className="license-number">No.{licenseNo}</div>
                  <div className="license-target">{` ${
                    licenseCount - remainCount
                  }/${licenseCount}${formatMessage({
                    id: 'StartExam_35',
                    defaultMessage: '명',
                  })}`}</div>
                  <div className="license-period">{` ${timeFormatFromUTCEpoch(
                    startEpoch,
                    3,
                  )} ~ ${timeFormatFromUTCEpoch(endEpoch, 3)}`}</div>
                </div>
              )}
              {[16].includes(licenseType) && (
                <div>
                  {CommonOpt.isJapan !== 1 && periodCount === 1 && (
                    <div className="license-name">
                      {`· ${LicenseString.EduDuration} (${periodCnt}${formatMessage({
                        id: 'StartExam_35',
                        defaultMessage: '명',
                      })} ${formatMessage({
                        id: 'License_9',
                        defaultMessage: '부족',
                      })})`}
                    </div>
                  )}
                  <div className="license-number">No.{licenseNo}</div>
                  <div className="license-target">{` ${
                    licenseCount - remainCount
                  }/${licenseCount}${formatMessage({
                    id: 'StartExam_35',
                    defaultMessage: '명',
                  })}`}</div>
                  <div className="license-period">{` ${changeEpoch(startEpoch)} ~ ${changeEpoch(
                    endEpoch,
                  )}`}</div>
                </div>
              )}
              {[32].includes(licenseType) && (
                <div>
                  {CommonOpt.isJapan !== 1 && timesperiodCount === 1 && (
                    <div className="license-name">
                      {`· ${LicenseString.EduDurationTimes} (${timesCnt}${formatMessage({
                        id: 'StartExam_35',
                        defaultMessage: '명',
                      })} ${formatMessage({
                        id: 'License_9',
                        defaultMessage: '부족',
                      })})`}
                    </div>
                  )}
                  <div className="license-number">No.{licenseNo}</div>
                  <div>
                    {` ${licenseCount}${formatMessage({
                      id: 'StartExam_35',
                      defaultMessage: '명',
                    })}`}
                  </div>
                  <div>
                    {` ${examLimitCount - remainCount}/${examLimitCount}${formatMessage({
                      id: 'License_13',
                      defaultMessage: '회',
                    })}`}
                  </div>
                  <div className="license-period">{` ${timeFormatFromUTCEpoch(
                    startEpoch,
                    3,
                  )} ~ ${timeFormatFromUTCEpoch(endEpoch, 3)}`}</div>
                </div>
              )}
            </div>
          </div>
        );
      });
    }
  }

  // 교육 실시 모달 상단
  const startEduHeader = useMemo(() => {
    if (step === 2) {
      return (
        <>
          <div className="step-area">
            <img src="/img/exam/startExam/step5.png" alt="step-progress" />
          </div>
          <div className="modal-title">
            {eduData.eduResult?.error
              ? formatMessage({
                  id: 'StartExam_61',
                  defaultMessage: '오류가 발생하여 훈련이 실행되지 않았습니다.',
                })
              : parse(
                  formatMessage({
                    id: 'Edu_114',
                    defaultMessage:
                      "교육의 <div class='complete'><span class='text'>예약 또는 실행</span><span class='highlighter'/></div>이 완료되었습니다.",
                  }),
                )}
          </div>
        </>
      );
    }

    return (
      <div className="modal-title-area">
        <div className="modal-main-title">
          {formatMessage({ id: 'Edu_1', defaultMessage: '교육 실시' })}
        </div>
        <div className="modal-sub-title">
          {formatMessage({
            id: 'Edu_40',
            defaultMessage:
              '교육명 및 교육기간을 입력하고, 대상자 태그 및 교육 컨텐츠를 선택합니다.',
          })}
        </div>
      </div>
    );
  }, [step]);

  const notExistTarget = !values.isAllTarget && values.tagInfoArray?.length < 1;
  const showInfoMessage = CommonOpt.isJapan && connectedExamStatus !== 'end' && values.isInfection;
  const resultMessages = {
    edu155: formatMessage({
      id: 'Edu_155',
      defaultMessage: '종료되지 않은 훈련을 연결하는 경우, ',
    }),
    edu156: formatMessage({
      id: 'Edu_156',
      defaultMessage: '해당 훈련의 결과에 따라 교육이 실시되지 않을 수 있습니다. ',
    }),
    edu157: formatMessage({
      id: 'Edu_157',
      defaultMessage: '그 경우, 교육의 재설정이 필요하므로 주의해 주시기 바랍니다.',
    }),
  };

  return (
    <div>
      <button type="button" className="brand-square-btn" onClick={() => toggleModal(true)}>
        {formatMessage({ id: 'Edu_1', defaultMessage: '교육 실시' })}
      </button>
      {openModal && (
        <ModalTemplate
          className={`start-exam-modal start-edu-modal modal-665 ${localStorage.getItem(
            'language',
          )}`}
          isCloseBlack
          visible={openModal}
          title={startEduHeader}
          onCancel={() => toggleModal(false)}
          footer={
            <>
              {/* {step === 2 && (
                <button key="cancel" className="footer-btn" type="submit" onClick={prevStep}>
                  <img src="/img/exam/startExam/btn_prev.png" alt="prev" />
                  {formatMessage({ id: 'Button_6', defaultMessage: '이 전' })}
                </button>
              )} */}
              {step === 2 ? (
                <button
                  key="ok"
                  className="footer-btn ok"
                  type="button"
                  onClick={() => toggleModal(false)}
                >
                  {formatMessage({ id: 'Button_4', defaultMessage: '확 인' })}
                </button>
              ) : (
                <button
                  key="ok"
                  className={`footer-btn ok ${
                    Object.keys(errors).length > 0 ||
                    loading ||
                    (notExistTarget && connectedExamStatus === 'end')
                      ? 'disabled'
                      : ''
                  }`}
                  type="submit"
                  onClick={handleSubmit(onCheckEduLicense)}
                >
                  {formatMessage({ id: 'Button_9', defaultMessage: '실 행' })}
                </button>
              )}
            </>
          }
        >
          {/* 로딩 */}
          <Loading loading={loading} />

          <form autoComplete="off">
            {/* STEP 1 */}
            {step === 1 && (
              <div className="modal-content">
                {/* 교육명 */}
                <div className="content-item">
                  <div className="input-title">
                    {formatMessage({ id: 'Edu_28', defaultMessage: '교육명' })}*
                  </div>
                  <FormTextField
                    name="eduName"
                    defaultValue={values.eduName}
                    error={errors.eduName}
                    arrowPosition="top"
                    register={register}
                    onChange={(e: any) => setValues({ ...values, eduName: e.target.value })}
                    validation={{
                      validate: {
                        required: (value: any) => valid.required(value),
                        name: (value: any) => valid.name(value),
                      },
                    }}
                  />
                </div>

                {/* 시작일자/종료일자 */}
                <div className="content-item">
                  <div className="date-picker-area">
                    <div className="date-picker-item">
                      <div className="text-field-title">
                        {formatMessage({ id: 'Date_11', defaultMessage: '시작일자' })}*
                      </div>
                      <FormDatePicker
                        control={control}
                        name="startEpoch"
                        value={values.startEpoch}
                        error={errors.startEpoch}
                        handleOnChange={handleOnChangeDate}
                        handleDisableDate={handleDisableDate}
                        helperText={
                          moment() < values.startEpoch &&
                          formatMessage({ id: 'Edu_42', defaultMessage: '교육을 예약함' })
                        }
                        disabled={ingExamEndDate}
                      />
                    </div>
                    <div className="date-picker-item">
                      <div className="text-field-title">
                        {formatMessage({ id: 'Date_12', defaultMessage: '종료일자' })}*
                      </div>
                      <FormDatePicker
                        control={control}
                        name="endEpoch"
                        value={values.endEpoch}
                        error={errors.endEpoch}
                        validation={{
                          validate: {
                            required: (value: any) => valid.required(value || values.endEpoch),
                          },
                        }}
                        handleOnChange={handleOnChangeDate}
                        handleDisableDate={handleDisableDate}
                        helperText={
                          values.endEpoch &&
                          `${formatMessage({
                            id: 'Period_4',
                            defaultMessage: '교육 기간',
                          })}: ${moment
                            .duration(values.endEpoch.diff(values.startEpoch))
                            .humanize()}`
                        }
                        arrowPosition="top"
                        // disabled={values.endEpoch < moment().startOf('day')}
                      />
                    </div>
                  </div>
                </div>

                {/* 교육 컨텐츠 */}
                <div className="content-item">
                  <div className="input-title">
                    <div className="edu-attach-text">
                      {formatMessage({ id: 'Edu_10', defaultMessage: '교육 컨텐츠' })}
                      <Popover
                        overlayClassName="edu-attach-popover full"
                        placement="right"
                        content={
                          <>
                            <b>
                              {formatMessage({
                                id: 'Edu_98',
                                defaultMessage: '교육 컨텐츠 유형별 등록 가능 개수',
                              })}
                            </b>
                            {CommonOpt.isJapan ? (
                              <div>
                                {formatMessage({
                                  id: 'Edu_100',
                                  defaultMessage:
                                    '최대 3개의 컨텐츠를 혼합하여 등록할 수 있습니다.',
                                })}
                              </div>
                            ) : (
                              <>
                                <div>
                                  {`- MP4(multiple) : ${formatMessage({
                                    id: 'Edu_99',
                                    defaultMessage:
                                      '같은 유형인 1개의 컨텐츠만 등록할 수 있습니다.',
                                  })}`}
                                </div>
                                <div>
                                  {`- YouTube, PDF, MP4(single) : ${formatMessage({
                                    id: 'Edu_100',
                                    defaultMessage:
                                      '최대 3개의 컨텐츠를 혼합하여 등록할 수 있습니다.',
                                  })}`}
                                </div>
                              </>
                            )}
                          </>
                        }
                      >
                        <img src="/img/exam/startExam/btn_qmark.png" alt="help" />*
                      </Popover>
                    </div>
                  </div>

                  <FormSelectField
                    className="multi-select"
                    name="eduAttachNoArray"
                    control={control}
                    error={errors.eduAttachNoArray}
                    option={eduAttachMenu}
                    handleOnChange={handleAttachSelectList}
                    mode="multiple"
                    showArrow
                    placeholder={formatMessage({
                      id: 'Edu_44',
                      defaultMessage: '교육 컨텐츠를 선택하세요.',
                    })}
                    value={values.eduAttachNoArray}
                    tagRender={(props: any) => eduAttachTagRender(props, 'eduAttachNoArray')}
                    filterOption={(input: any, option: any) => {
                      return option.children[2].toLowerCase().includes(input.toLowerCase());
                    }}
                    validation={{
                      validate: {
                        required: (value: any) => valid.required(value),
                      },
                    }}
                  />
                  {/* 연결훈련 */}
                  <div className="content-item">
                    <div className="text-field-title">
                      <div className="edu-attach-text">
                        {formatMessage({ id: 'Edu_47', defaultMessage: '연결훈련' })}
                        <Popover
                          overlayClassName="edu-attach-popover full"
                          placement="right"
                          content={
                            <span>
                              <b>
                                {formatMessage({
                                  id: 'Edu_142',
                                  defaultMessage: '연결할 훈련이 진행 중인 경우',
                                })}
                              </b>
                              <div>
                                {formatMessage({
                                  id: 'Edu_143',
                                  defaultMessage:
                                    "진행 중인 훈련은 훈련 종료일 다음날부터 '교육 시작일'을 설정할 수 있습니다.",
                                })}
                              </div>
                            </span>
                          }
                        >
                          <img src="/img/exam/startExam/btn_qmark.png" alt="help" />
                        </Popover>
                      </div>
                    </div>
                    <FormSelectField
                      className={`multi-select ${examData?.length > 0 ? '' : 'disabled'}`}
                      name="examNoArray"
                      control={control}
                      error={errors.examNoArray}
                      option={examMenu}
                      handleOnChange={handleSelectList}
                      mode="multiple"
                      showArrow
                      placeholder={formatMessage({
                        id: 'Edu_45',
                        defaultMessage: '교육과 연결시킬 훈련을 선택하세요.',
                      })}
                      value={values.examNoArray}
                      tagRender={(props: any) => examTagRender(props, 'examNoArray')}
                      filterOption={(input: any, option: any) => {
                        return option.children.props.children[1].props.children[0].props.children
                          .toLowerCase()
                          .includes(input.toLowerCase());
                      }}
                    />
                    {/* {errors.examNoArray?.message && <div className='error-message mt-5'>{errors.examNoArray?.message}</div>} */}

                    {/* 감염/정보유출 훈련대상만 실시 */}
                    <Controller
                      control={control}
                      name="isInfection"
                      render={({ onChange, onBlur, value, name, ref }) => (
                        <Checkbox
                          className="modal-checkbox"
                          onChange={(e) => {
                            onChange(e.target.checked);
                            setValues({
                              ...values,
                              isInfection: e.target.checked,
                              tagInfoArray: [],
                              isAllTarget: false,
                            });
                          }}
                          checked={values.isInfection}
                          disabled={values.examNoArray?.length < 1 || values.isAllTarget}
                        >
                          {formatMessage({
                            id: 'Edu_122',
                            defaultMessage: "'감염', '정보유출' 훈련대상만 실시",
                          })}
                        </Checkbox>
                      )}
                    />
                  </div>

                  {/* 대상자 태그 선택 */}
                  <div className="content-item">
                    <div className="input-title">
                      {formatMessage({
                        id: 'Tag_17',
                        defaultMessage: '대상자 태그',
                      })}
                      *
                    </div>
                    <div>{tagListComponent}</div>
                    <div>
                      <Controller
                        control={control}
                        name="isAllTarget"
                        render={({ onChange }) => (
                          <Checkbox
                            className="modal-checkbox"
                            onChange={(e) => {
                              onChange(e.target.checked);
                              setValues({
                                ...values,
                                isAllTarget: e.target.checked,
                                isInfection: false,
                                tagInfoArray: [],
                              });
                            }}
                            checked={values.isAllTarget}
                            disabled={values.isInfection || !hasTarget || connectedExamStatus === 'ongoing'}
                          >
                            {formatMessage({
                              id: 'Edu_138',
                              defaultMessage: '모든 교육 대상자를 지정하여 교육을 실시합니다.',
                            })}
                          </Checkbox>
                        )}
                      />
                      {connectedExamStatus === 'mix' && (
                        <div className="error-message ing-exam">
                          {`※ ${formatMessage({
                            id: 'Edu_147',
                            defaultMessage: '종료된 훈련의 대상자 태그만 선택 가능합니다.',
                          })}`}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* STEP 2 */}
            {/* {step === 2 && (
              <div className="modal-content">
                {eduData?.quizData?.length > 0 &&
                  eduData.quizData?.map((quiz: any, index: number) => {
                    return (
                      <div className="content-item" key={quiz.fileNo}>
                        <div className="text-field-title">{`${index + 1}. ${quiz.fileName}`}</div>
                        <FormSelectField
                          className={`multi-select ${quiz.quizInfo ? '' : 'disabled'}`}
                          name={`quizNoArray${quiz.fileNo}`}
                          control={control}
                          error={errors[`quizNoArray${quiz.fileNo}`]}
                          option={makeQuizOption(quiz.fileNo)}
                          handleOnChange={handleSelectList}
                          mode="multiple"
                          showArrow
                          tagRender={(props: any) =>
                            quizTagRender(props, `quizNoArray${quiz.fileNo}`)
                          }
                          placeholder={formatMessage({
                            id: 'Edu_48',
                            defaultMessage: '퀴즈를 선택하세요.',
                          })}
                          value={values[`quizNoArray${quiz.fileNo}`]}
                          filterOption={(input: any, option: any) => {
                            return option.children.toLowerCase().includes(input.toLowerCase());
                          }}
                        />
                      </div>
                    );
                  })}
              </div>
            )} */}

            {/* 라이선스 오류 표시 */}
            {licenseError.isValidLicense === 0 && (
              <div className="license-error">
                <div className="license-error-content">
                  {step === 3 && (
                    <div className="license-error-text">
                      {/* <img src={failImage} alt="fail" /> */}
                      <img src="/img/exam/startExam/ico_fail_s.png" alt="fail" />
                      {formatMessage({
                        id: 'StartExam_60',
                        defaultMessage: '선택한 대상자의 라이선스가 필요합니다.',
                      })}
                    </div>
                  )}
                  <div className="license-error-popup">
                    <div className="popup-header">
                      {formatMessage({
                        id: 'License_2',
                        defaultMessage: '라이선스 현황',
                      })}
                    </div>
                    <div className="popup-content">{licenseContent}</div>
                  </div>
                </div>
              </div>
            )}
          </form>

          {summaryModal && (
            <ModalTemplate
              className="result-summary-modal modal-464"
              visible={summaryModal}
              title={formatMessage({ id: 'Edu_109', defaultMessage: '교육 내용 요약' })}
              onCancel={() => setSummaryModal(false)}
              onOk={() => {
                setSummaryModal(false);
                onStartEdu();
              }}
              okText={formatMessage({ id: 'Edu_111', defaultMessage: '교육예약・실시' })}
              cancelText={formatMessage({ id: 'Button_5', defaultMessage: '닫 기' })}
            >
              {summaryInfo?.map((data: any) => (
                <div className="result-summary-row" key={data.text}>
                  <div className="result-summary-title">· {data.text}</div>
                  <div className="result-summary-text ellipsis">{data.value}</div>
                </div>
              ))}

              {!!showInfoMessage && (
                <div className="result-info-message">
                  <b>{resultMessages.edu155}</b>
                  <span>{resultMessages.edu156}</span>
                  <span>{resultMessages.edu157}</span>
                </div>
              )}
            </ModalTemplate>
          )}

          {step === 2 && (
            <ExecuteResult
              resultData={summaryInfo}
              goToResult={goToEduResult}
              goToText={formatMessage({
                id: 'Header_3',
                defaultMessage: '교육결과',
              })}
            />
          )}
        </ModalTemplate>
      )}
    </div>
  );
}

export default withRouter(StartEdu);
