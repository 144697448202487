import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import parse from 'html-react-parser';
import { timeFormatFromUTCEpoch, nowEpoch } from 'utils/commonFunctions';
import { Option } from 'utils/commonValues';
import { changeSummaryState } from 'store/exam';

// import checkImage from 'img/exam/startExam/ico_check_y.png';

function StartExamStep5(props: any) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { result, moveRouter } = props;
  const {
    examName,
    examNoArray,
    examStartEpoch,
    examEndEpoch,
    targetCount,
    sendReservePeriod,
    sendReserveStartHour,
    sendReserveEndHour,
    attachScreenLockEpoch,
    examTemplateNameArray,
    serviceTemplateNameArray,
    licenseType,
    licenseInfo,
  } = result;

  const LicenseString: any = { 
    Duration: formatMessage({ id: 'License_7', defaultMessage: '기간 라이선스' }),
    Times: formatMessage({ id: 'License_8', defaultMessage: '횟수 라이선스' }),
    DurationTimes: formatMessage({ id: 'License_58', defaultMessage: '기간+횟수 라이선스' }),
    EduDuration: 
      formatMessage({ id: 'License_60', defaultMessage: '[교육]' }) 
      + formatMessage({ id: 'License_7', defaultMessage: '기간 라이선스' }),
    EduDurationTimes: 
      formatMessage({ id: 'License_60', defaultMessage: '[교육]' }) 
      + formatMessage({ id: 'License_58', defaultMessage: '횟수 라이선스' })
  };

  if(Option.isJapan === 1){
    LicenseString.Duration = 
      formatMessage({ id: 'License_61', defaultMessage: '[훈련]' }) 
      + formatMessage({ id: 'License_7', defaultMessage: '기간 라이선스' });
    LicenseString.Times = 
      formatMessage({ id: 'License_61', defaultMessage: '[훈련]' }) 
      + formatMessage({ id: 'License_8', defaultMessage: '횟수 라이선스(무제한)' });
    LicenseString.DurationTimes = 
      formatMessage({ id: 'License_61', defaultMessage: '[훈련]' }) 
      + formatMessage({ id: 'License_58', defaultMessage: '횟수 라이선스' });
    LicenseString.EduDuration = 
      formatMessage({ id: 'License_60', defaultMessage: '[교육]' }) 
      + formatMessage({ id: 'License_7', defaultMessage: '기간 라이선스' });
    LicenseString.EduDurationTimes = 
      formatMessage({ id: 'License_60', defaultMessage: '[교육]' }) 
      + formatMessage({ id: 'License_58', defaultMessage: '횟수 라이선스' });
  }
  // 훈련 결과 요약
  const returnData: any = {};
  Object.keys(result).forEach((key: any, index: number) => {
    if (key === 'examName') {
      returnData['0'] = {
        title: formatMessage({ id: 'Exam_1', defaultMessage: '훈련명' }),
        text: `${examName} (No.${examNoArray.join(', ')})`,
      };
    } else if (key === 'examStartEpoch') {
      returnData['1'] = {
        title: formatMessage({ id: 'Period_3', defaultMessage: '훈련 기간' }),
        text: `${timeFormatFromUTCEpoch(examStartEpoch, 3)} ~ ${timeFormatFromUTCEpoch(
          examEndEpoch,
          3,
        )} ${
          examStartEpoch > nowEpoch() + 1000
            ? formatMessage({ id: 'StartExam_59', defaultMessage: '(예약됨)' })
            : ''
        }`,
      };
    } else if (key === 'targetCount') {
      returnData['2'] = {
        title: formatMessage({ id: 'Target_1', defaultMessage: '훈련 대상' }),
        text: `${targetCount}${formatMessage({ id: 'StartExam_35', defaultMessage: '명' })}`,
      };
    } else if (key === 'sendReservePeriod') {
      const startDate = timeFormatFromUTCEpoch(examStartEpoch, 3);
      const endDate = timeFormatFromUTCEpoch(examStartEpoch + 86400 * (sendReservePeriod - 1), 3);
      returnData['3'] = {
        title: formatMessage({ id: 'Send_3', defaultMessage: '훈련 메일 나눔 발송' }),
        text: `${
          sendReservePeriod === 1
            ? startDate
            : `${startDate} ~ ${endDate} (${sendReserveStartHour}${formatMessage({
                id: 'Time_1',
                defaultMessage: '시',
              })} ~ ${sendReserveEndHour}${formatMessage({
                id: 'Time_1',
                defaultMessage: '시',
              })})`
        }`,
      };
    } else if (key === 'attachScreenLockEpoch') {
      returnData['4'] = {
        title: formatMessage({ id: 'StartExam_52', defaultMessage: 'PC 잠금 화면 실행 시간' }),
        text: timeFormatFromUTCEpoch(attachScreenLockEpoch, 2),
      };
    } else if (key === 'examTemplateNameArray' || key === 'serviceTemplateNameArray') {
      if (returnData.examTemplate) return;
      const templateArray: any = [];
      if (serviceTemplateNameArray) {
        templateArray.push(parse(`<div>· ${serviceTemplateNameArray.join('</div><div>· ')}`));
      }
      if (examTemplateNameArray) {
        templateArray.push(parse(`<div>· ${examTemplateNameArray.join('</div><div>· ')}`));
      }
      returnData['5'] = {
        title: formatMessage({ id: 'StartExam_5', defaultMessage: '훈련 양식' }),
        text: templateArray,
      };
    } else if (key === 'licenseType') {
      if (
        licenseInfo.length > 0 &&
        (licenseType === 1 || licenseType === 2 || licenseType === 4 || licenseType === 8)
      ) {
        const licenseComp: any = [];
        licenseInfo.forEach((license: any) => {
          licenseComp.push(
            <div className="result-license-wrap" key={license.licenseNo}>
              <div className="result-license-title">
                {`· `}
                {licenseType === 8 && LicenseString.DurationTimes}
                {licenseType === 4 && LicenseString.Times}
                {(licenseType === 1 || licenseType === 2) && LicenseString.Duration}
              </div>
              <div className="result-license-content">
                <div className="license-number">{`No.${license.licenseNo} `}</div>
                {(licenseType === 1 || licenseType === 2) && (
                  <div>
                    <div>
                      {`${timeFormatFromUTCEpoch(license.startEpoch, 3)} ~ ${timeFormatFromUTCEpoch(
                        license.endEpoch,
                        3,
                      )}`}
                    </div>
                    {Option.isJapan !== 1 && (
                      <div className="point-text">
                        {`(${formatMessage({
                          id: 'Period_2',
                          defaultMessage: '남은 기간',
                        })}: ${timeFormatFromUTCEpoch(license.endEpoch)})`}
                      </div>
                    )}
                  </div>
                )}

                {licenseType === 4 && (
                  <>
                    <div>{formatMessage({ id: 'License_11', defaultMessage: '훈련 횟수' })}</div>
                    <div>
                      {`${license.limitInfo.length} / ${license.examLimitCount}${formatMessage({
                        id: 'License_13',
                        defaultMessage: '회',
                      })}`}
                    </div>
                    <div className="point-text">{`(${formatMessage({
                      id: 'License_12',
                      defaultMessage: '잔여 횟수',
                    })} : ${license.examLimitCount - license.limitInfo.length}${formatMessage({
                      id: 'License_13',
                      defaultMessage: '회',
                    })})`}</div>
                  </>
                )}
                {licenseType === 8 && (
                  <>
                    <div>
                      <div>
                        {`${timeFormatFromUTCEpoch(
                          license.startEpoch,
                          3,
                        )} ~ ${timeFormatFromUTCEpoch(license.endEpoch, 3)}`}
                      </div>
                      {Option.isJapan !== 1 && (
                        <div className="point-text">
                          {`(${formatMessage({
                            id: 'Period_2',
                            defaultMessage: '남은 기간',
                          })}: ${timeFormatFromUTCEpoch(license.endEpoch)})`}
                        </div>
                      )}
                      <div>{formatMessage({ id: 'License_11', defaultMessage: '훈련 횟수' })}</div>
                      <div>
                        {`${license.limitInfo.length} / ${license.examLimitCount}${formatMessage({
                          id: 'License_13',
                          defaultMessage: '회',
                        })}`}
                      </div>
                      {Option.isJapan !== 1 && (
                        <div className="point-text">{`(${formatMessage({
                          id: 'License_12',
                          defaultMessage: '잔여 횟수',
                        })} : ${license.examLimitCount - license.limitInfo.length}${formatMessage({
                          id: 'License_13',
                          defaultMessage: '회',
                        })})`}</div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>,
          );
        });

        returnData['6'] = {
          title: formatMessage({ id: 'License_2', defaultMessage: '라이선스 현황' }),
          text: licenseComp,
        };
      }
    }
  });

  // 새로 실시할 훈련 중 첫번째 훈련의 결과로 이동
  const goToResult = () => {
    dispatch(changeSummaryState({ key: 'selectedExamNo', value: examNoArray[0] }));
    moveRouter('/exam');
  };

  return (
    <div className="step5-content">
      <div className="content-item ">
        <div className="result-scroll">
          {Object.keys(returnData)
            .sort()
            .map((key: any) => {
              return (
                <div className="result-row" key={returnData[key].title}>
                  <img
                    className="yellow-check-icon"
                    src="/img/exam/startExam/ico_check_y.png"
                    alt="check"
                  />
                  <div className="result-title">{returnData[key].title}</div>
                  <div className="result-text">{returnData[key].text}</div>
                </div>
              );
            })}
        </div>
        <div className="result-move-button" onClick={goToResult} aria-hidden="true">
          <span>
            {formatMessage(
              {
                id: 'StartExam_58',
                defaultMessage: '{result}에서 세부 내용을 확인할 수 있습니다.',
              },
              {
                result: (
                  <span className="brand-color">
                    {formatMessage({ id: 'Header_1', defaultMessage: '훈련결과' })}
                  </span>
                ),
              },
            )}
          </span>
        </div>
      </div>
    </div>
  );
}

export default StartExamStep5;
