import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { copyTemplate } from 'store/template';
import { timeFormatFromUTCEpoch } from 'utils/commonFunctions';
import * as valid from 'utils/validation';
import FormTextField from 'components/common/FormTextField';
import Loading from 'components/common/Loading';
import ModalTemplate from 'components/common/ModalTemplate';
import { Option } from 'utils/commonValues';

interface ExamTemplateProps {
  visible: boolean; // 모달 띄우기
  isAdmin: boolean; // 사용자, 관리자 구분
  templateInfo: any; // 템플릿 정보
  toggleModal: (open: boolean) => void;
}

function ExamTemplateCopy({ visible, isAdmin, templateInfo, toggleModal }: ExamTemplateProps) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, setError, errors } = useForm({
    mode: 'onChange',
  });

  const { templateNo, serviceTemplateNo, templateName, regEpoch, phishingName, examType } = templateInfo;

  // 템플릿 복제
  const onCopyTemplate = async (formValues: any) => {
    setLoading(true);
    try {
      const params: any = {};
      if (serviceTemplateNo) {
        params.serviceTemplateNo = serviceTemplateNo;
      } else if (templateNo) {
        params.examTemplateNo = templateNo;
      }

      if (isAdmin && formValues.serviceNo) {
        params.serviceNo = parseInt(formValues.serviceNo, 10);
      }
      if (formValues.templateName) {
        params.templateName = formValues.templateName;
      }
      if (formValues.phishingName) {
        params.phishingName = formValues.phishingName;
      }

      const response: any = await dispatch(copyTemplate(params));
      if (response?.data?.error) {
        if (response?.data?.error && response.data?.error[0].errInfo) {
          setError('serviceNo', {
            type: 'wrong',
            message: formatMessage({
              id: 'Template_75',
              defaultMessage: '잘못된 서비스 번호입니다.',
            }),
          });
        }
        setLoading(false);
      } else {
        setLoading(false);
        toggleModal(false);
      }
    } catch (error) {
      console.log('ExamTemplateCopy onCopyTemplate', error);
    }
  };

  return (
    <div>
      <ModalTemplate
        className="examtemplate-copy-modal modal-464"
        visible={visible}
        title={
          <div className="modal-template-header">
            <div className="title">
              {formatMessage({ id: 'Template_6', defaultMessage: '훈련 양식(템플릿) 복제' })}
            </div>
          </div>
        }
        onOk={handleSubmit(onCopyTemplate)}
        onCancel={() => toggleModal(false)}
        okText={formatMessage({ id: 'Button_23', defaultMessage: '복 제' })}
        cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
        loading={loading}
        greyButton
      >
        <Loading loading={loading} />

        <div className="modal-explain-text">
          <div>
            {formatMessage({
              id: 'Template_72',
              defaultMessage: '템플릿을 복제하여 새로 생성합니다.',
            })}
          </div>
          <div>
            {formatMessage({
              id: 'Template_73',
              defaultMessage: '다음 템플릿을 복제합니까?',
            })}
          </div>
        </div>
        <div className="modal-border-box">
          <ul className="modal-item-list">
            <li>
              <div className="item-title">No</div>
            </li>
            <div>
              {serviceTemplateNo ||
                `${templateNo} (${formatMessage({
                  id: 'Template_71',
                  defaultMessage: '기본 제공',
                })})`}
            </div>
          </ul>
          <ul className="modal-item-list">
            <li>
              <div className="item-title">
                {formatMessage({ id: 'Template_24', defaultMessage: '템플릿명' })}
              </div>
            </li>
            <div>{templateName}</div>
          </ul>
          <ul className="modal-item-list">
            <li>
              <div className="item-title">
                {formatMessage({ id: 'Date_3', defaultMessage: '등록일시' })}
              </div>
            </li>
            <div>{timeFormatFromUTCEpoch(regEpoch)}</div>
          </ul>
          <div className="service-no-area">
            <form autoComplete="off" onSubmit={(e: any) => e.preventDefault()}>
              {/* 템플릿 복제 이름 */}
              {(!Option.isJapan || !!isAdmin) && (
                <>
                  <div className="text-field-title">
                    {formatMessage({ id: 'Template_24', defaultMessage: '템플릿명' })}
                  </div>
                  <FormTextField
                    name="templateName"
                    error={errors.templateName}
                    register={register}
                    arrowPosition="top"
                    validation={{
                      validate: {
                        required: (value: any) => valid.required(value),
                        number: (value: any) => valid.templateName(value),
                      },
                    }}
                    defaultValue={templateName}
                  />
                  {examType === 4 && (
                    <>
                      <div className="text-field-title">
                        {formatMessage({ id: 'Phishing_5', defaultMessage: '피싱 제목' })}
                      </div>
                      <FormTextField
                        name="phishingName"
                        error={errors.phishingName}
                        register={register}
                        arrowPosition="top"
                        validation={{
                          validate: {
                            required: (value: any) => valid.required(value),
                            number: (value: any) => valid.templateName(value),
                          },
                        }}
                        defaultValue={phishingName}
                      />
                    </>
                  )}
                </>
              )}
              {/* 대상 서비스 번호 */}
              {!!isAdmin && (
                <>
                  <div className="text-field-title">
                    {formatMessage({
                      id: 'Template_74',
                      defaultMessage: '대상 서비스 번호 (미입력 시 비공개 기본 템플릿으로 복제)',
                    })}
                  </div>
                  <FormTextField
                    name="serviceNo"
                    error={errors.serviceNo}
                    register={register}
                    arrowPosition="top"
                    validation={{
                      validate: {
                        blank: (value: any) => valid.blank(value),
                        number: (value: any) => valid.number(value),
                      },
                    }}
                  />
                </>
              )}
            </form>
          </div>
        </div>
      </ModalTemplate>
    </div>
  );
}

export default ExamTemplateCopy;
